import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DiscountPipe } from './discount.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DiscountPipe],
  exports: [DiscountPipe]
})
export class UtilDiscountModule {}
